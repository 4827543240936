import React, { Component } from 'react'

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">highlights</span>
                <h2 className="colorlib-heading animate-box">Timeline</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Board Member (Presidential Appointment - Biden Administration) at United States Access Board <span>Washington, District of Columbia (2022 - Present)</span></h2>
                        <p>The U.S. Access Board is a federal agency that promotes equality for people with disabilities through leadership in accessible design and the development of accessibility guidelines and standards for the built environment, transportation, communication, medical diagnostic equipment, and information technology.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Consultant at World Institute on Disability <span>(September 2020 - September 2021)</span></h2>
                        <p>Write and develop policy proposals and engagement materials, pertaining to various projects within WID including the Global Alliance for Disaster Resource Acceleration (GADRA); streamlined GADRA’s homepage on the WID website to clarify information and ensure user accessibility; edited two entries for WID’s COVID-19 Op-Ed blog section; Strategized and composed two formal introductory letters to promote WIDs partnership with other non-profit organizations. </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Corp Member at Teach For America <span>Nashville, Tennessee (May 2018 - August 2020)</span></h2>
                        <p>Instructed approximately 190 9th and 12th grade students in HTML, CSS, JavaScript, and App Lab in six daily 47-minute Computer Science classes; designed and executed lesson plans that are aligned to school and state standards on a weekly basis; advised 17 9th grade students on academics, higher education, and life after high school; started and coached 31 students in FIRST Robotics, raising over $100,000, acquiring eight corporate sponsors, and connecting students to ten highly skilled technical mentors; helped manage schoolwide transition online during COVID-19 by developing data-tracking resources to analyze student engagement and educating faculty on equitable online classroom strategies; formerly taught 184 9th and 10th grade students Latin 1 and developed the foreign language curriculum for the 2018-2019 school year.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Inaugural Kerry Fellow, Kerry Initiative at Yale University <span>New Haven, Connecticut (September 2017 - May 2018)</span></h2>
                        <p>Constructed and Co-authored 15-page automation and globalization memorandum for Secretary John Kerry on how to best meet the needs of a changing workforce and global environment; organized and facilitated the Yale Climate Conference in September of 2017 and the Conversation with Al Gore in October of 2017, hosting high-ranking politicians, celebrities, and NGO leaders; researched Secretary Kerry’s history as an anti-Vietnam War Activist and the Syrian Refugee Crisis for his book, Every Day Is Extra; participated in weekly oral recommendations to the Secretary on international and domestic topics such as Brexit, Disability Rights, Clean Coal, climate change, and Iran-Saudi relations.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Commissioner, Commission on Disabilities at City of New Haven <span>New Haven, Connecticut (September 2015 - May 2018)</span></h2>
                        <p>Recommended and obtained passage of an ordinance amendment through the Mayor and Board of Alders that reduced the amount of time allotted to landlords to repair elevators (Ordinance No. 2016-0025).</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Intern, Office of Knox County Mayor Tim Burchett at Knox County <span>Knoxville, Tennessee (June 2017 - September 2017)</span></h2>
                        <p>Reviewed and analyzed current market information on the automobile industry and autonomous vehicles to determine suitable and sustainable development projects in the region; designed an economic development plan to introduce emerging technological industries to the county; researched methods, such as traffic control and residential construction, to reduce sprawl and increase density; condensed findings into two separate memorandums for the Mayor.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Intern, Office of Educational Technology at U.S. Department of Education <span>Washington, District of Columbia (July 2016 - August 2016)</span></h2>
                        <p>Analyzed data and provided recommendation on current Personalized Learning pedagogy; wrote and published five articles for the Department in order to promote the #GoOpen Movement; interviewed and organized information from more than ten different school districts in order to determine the progress and success of Openly Licensed Educational Resources; promoted accessibility by reviewing documents for Sec. 508 of the Rehabilitation Act of 1973 accessibility compliance.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>Intern, State Office of U.S. Senator Bob Corker at United States Senate <span>Knoxville, Tennessee (June 2016 - July 2016)</span></h2>
                        <p>Communicated with more than 30 constituents a day; recorded constituent views on current hot-button issues including gun control, LGBTQI+ rights, and domestic security; solved problems for constituents in need such as finding healthcare, affordable housing resources, information on Veterans’ Affairs and Social Security benefits; participated in staff weekly strategic meetings; designed primary internship recruitment and training resources.</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
