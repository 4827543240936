import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                        <span className="heading-meta">About Us</span>
                        <h2 className="colorlib-heading">Who Am I?</h2>
                        <p>Benjamin Nadolsky of Knoxville, Tennessee is the principal of Nadolsky Consulting Group LLC, a firm specialized in education, accessibility, and disability rights. He is on the Board of Directors for the World Institute on Disability and the Pennhurst Preservation & Memorial Alliance Advisory Board. Additionally, he is a professional student at the University of Pennsylvania, receiving his JD/MBA, a Coelho Law Fellow, and a co-chair for the Yale Disability Alumni Group. Previously, he served as a high school teacher for two years in Nashville, TN. While teaching, he received his M. Ed. from Lipscomb University. In 2018, he received his B.A. in Global Affairs and History from Yale University. There, he served through mayoral appointment on the City of New Haven’s Commission on Disabilities, founded Yale’s only undergraduate organization dedicated to disability rights, Disability Empowerment for Yale, and worked as an Inaugural Kerry Yale College Fellow for U.S. Special Presidential Envoy for Climate John Kerry. During his college career, Nadolsky also interned with the U.S. Department of Education Office of Educational Technology, the Office of Former U.S. Senator Bob Corker (TN), and the Office of Former Knox County Mayor Tim Burchett (TN).</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">Skills & Expertise</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Writing</h3>
                    <p>I have a strong foundation in writing, having been published a multitude of times.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Disability Rights</h3>
                    <p>Disability rights are one of my core strengths, when it comes to policy.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Political Campaigns</h3>
                    <p>I have experience working on multiple political campaigns and currently serve in the Biden administration.</p>
                </div>
                </div>
            </div>
            {/*
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
        </div>
      </div>
    )
  }
}
